// Header.js
import React from "react";
import classes from "../styles/Header.module.css";
import Header from "./Header";

function FloatingHeader({ showFixedHeader }) {
  return (
    <nav
      className={classes.floatingHeader}
      style={{
        opacity: showFixedHeader ? 1 : 0,
        pointerEvents: showFixedHeader ? "" : "none",
      }}
    >
      <Header showFixedHeader={showFixedHeader} />
    </nav>
  );
}

export default FloatingHeader;
