import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2", // Primary color
    },
    secondary: {
      main: "#dc004e", // Secondary color
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
    caption: {
      fontSize: "14px",
    },
    h3: {
      fontWeight: 500,
      fontSize: "48px",
    },
    h4: {
      fontWeight: 500,
      fontSize: "32px",
    },
    h6: {
      fontWeight: 500,
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#252525",
          color: "white",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: "white",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: "#252525",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#ffffff29 !important",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          fontSize: "14px",
          /* "&.Mui-focused": {
            borderColor: "#000",
          },
          "&.MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(0,0,0, .23)",
          }, */
        },
        root: {
          /* "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "black", // Replace with your desired color
            border: "solid 1px rgba(0,0,0, .23)",
          }, */
          borderRadius: "0px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: "white",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "white",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          color: "#656565",
          borderColor: "#656565",
        },
      },
    },
  },
});
