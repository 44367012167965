import React from "react";
import { FaNodeJs } from "react-icons/fa";
import { FaCss3 } from "react-icons/fa";
import { RiTailwindCssFill } from "react-icons/ri";
import { SiZapier } from "react-icons/si";
import { SiGoogletagmanager } from "react-icons/si";
import { SiExpress } from "react-icons/si";
import { IoLogoJavascript } from "react-icons/io5";
import { FaHtml5 } from "react-icons/fa";
import { SiAwslambda } from "react-icons/si";
import { SiAmazonapigateway } from "react-icons/si";
import { SiAmazonaws } from "react-icons/si";
import { IoLogoAmplify } from "react-icons/io5";
import { BiLogoMongodb } from "react-icons/bi";
import { SiMui } from "react-icons/si";
import { FaBitbucket } from "react-icons/fa";
import { FaPython } from "react-icons/fa";
import { SiGoogleanalytics } from "react-icons/si";
import { SiHandlebarsdotjs } from "react-icons/si";
import { TbBrandFramerMotion } from "react-icons/tb";
import { FaHubspot } from "react-icons/fa";
import { FaStripe } from "react-icons/fa";
import { SiJira } from "react-icons/si";
import { motion } from "framer-motion";
import { FaReact } from "react-icons/fa";
import { SiOpenai } from "react-icons/si";
import dynamoDBpng from "../images/dynamo-db.png";
import s3svg from "../images/amazon-s3.svg";
import cognitopng from "../images/cognito-logo.png";
import "../styles/tech.css";

const SiDynamoDB = () => {
  return <img alt="dynamoDB" src={dynamoDBpng} className="icon2" />;
};

const Sis3 = () => {
  return <img alt="s3" src={s3svg} className="icon2" />;
};

const Sicognito = () => {
  return <img alt="cognito" src={cognitopng} className="icon2" />;
};

const secondaryTechList = [
  {
    id: 0,
    label: "Tailwind CSS",
    icon: RiTailwindCssFill,
    color: "text-sky-400",
    url: "https://tailwindcss.com/",
  },
  {
    id: 1,
    label: "AWS",
    icon: SiAmazonaws,
    color: "text-white",
    url: "https://aws.amazon.com/",
  },
  {
    id: 2,
    label: "Amplify",
    icon: IoLogoAmplify,
    color: "text-purple-500",
    url: "https://aws.amazon.com/amplify",
  },
  {
    id: 3,
    label: "S3",
    icon: Sis3,
    color: "text-white",
    url: "https://aws.amazon.com/pm/serv-s3",
  },
  {
    id: 4,
    label: "Cognito",
    icon: Sicognito,
    color: "text-red-600",
    url: "https://aws.amazon.com/pm/cognito",
  },
  {
    id: 5,
    label: "MongoDB",
    icon: BiLogoMongodb,
    color: "text-green-400",
    url: "https://www.mongodb.com/",
  },
  {
    id: 6,
    label: "Bitbucket",
    icon: FaBitbucket,
    color: "text-blue-500",
    url: "https://bitbucket.org/",
  },
  {
    id: 7,
    label: "MUI",
    icon: SiMui,
    color: "text-blue-500",
    url: "https://mui.com/material-ui/",
  },
  {
    id: 8,
    label: "Zapier",
    icon: SiZapier,
    color: "text-orange-500",
    url: "https://zapier.com/",
  },
  {
    id: 9,
    label: "Python",
    icon: FaPython,
    color: "text-yellow-300",
    url: "https://www.python.org/",
  },
  {
    id: 10,
    label: "Jira",
    icon: SiJira,
    color: "text-blue-500",
    url: "https://www.atlassian.com/software/jira",
  },
  {
    id: 11,
    label: "Google Tag Manager",
    icon: SiGoogletagmanager,
    color: "text-blue-500",
    url: "https://tagmanager.google.com/",
  },
  {
    id: 12,
    label: "Google Analytics",
    icon: SiGoogleanalytics,
    color: "text-orange-400",
    url: "https://analytics.google.com/",
  },
  {
    id: 13,
    label: "Stripe",
    icon: FaStripe,
    color: "text-white",
    url: "https://stripe.com/",
  },
  {
    id: 14,
    label: "Framer Motion",
    icon: TbBrandFramerMotion,
    color: "text-fuchsia-500",
    url: "https://www.framer.com/motion/",
  },
  {
    id: 15,
    label: "Hubspot",
    icon: FaHubspot,
    color: "text-orange-500",
    url: "https://www.hubspot.com/",
  },
  {
    id: 16,
    label: "Handlebars",
    icon: SiHandlebarsdotjs,
    color: "white",
    url: "https://handlebarsjs.com/",
  },
  {
    id: 17,
    label: "OpenAI",
    icon: SiOpenai,
    color: "white",
    url: "https://openai.com/",
  },
];

const SecondaryTechnologies = () => {
  const openLink = (link) => {
    window.open(link, "_blank");
  };

  return (
    <div className="relative left-0 sm:-left-2 ">
      <div className="absolute bottom-0 left-0 w-full h-4 bg-gradient-to-b from-transparent to-neutral-950 pointer-events-none" />
      <div
        className="flex flex-wrap p-1.5 items-center justify-center sm:justify-start gap-7 sm:gap-4 overflow-x-auto max-h-[calc(3*72px)] hide-scrollbar" // Assuming each icon has a max height of 64px
        /* style={{ maxHeight: "calc(3 * 64px)", overflowX: "auto" }} */
      >
        {secondaryTechList.map((tech) => {
          const IconComponent = tech.icon;
          return (
            <motion.div
              key={tech.id}
              whileHover={{ scale: 1.2 }}
              onHoverStart={(e) => {}}
              onHoverEnd={(e) => {}}
              className="rounded-xl md:rounded-2xl border-4 border-neutral-800 p-3 hover:cursor-pointer"
              onClick={() => openLink(tech.url)}
            >
              <IconComponent
                className={`text-3xl xs:text-3xl sm:text-3xl md:text-3xl lg:text-3xl ${tech.color}`}
              />
            </motion.div>
          );
        })}
      </div>
    </div>
  );
};

export default SecondaryTechnologies;
