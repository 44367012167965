import React from "react";
import classes from "../styles/AboutSection.module.css";
import PicturePanel from "../components/PicturePanel";
import headshot from "../images/pot-headshot4.jpg";
import jspic from "../images/jspic.png";
import csspic from "../images/csspic.png";
import zapierpic from "../images/zapierpic.png";
import reactpic from "../images/reactpic.png";
import awspic from "../images/awspic.png";
import stripepic from "../images/stripepic.png";
import gapic from "../images/gapic.png";
import jirapic from "../images/jirapic.png";
import { motion } from "framer-motion";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Technologies from "../components/Technologies";
import SecondaryTechnologies from "../components/SecondaryTechnologies";

function AboutSection(props) {
  const elements = [
    { imageUrl: jspic, label: "Java Script" },
    { imageUrl: csspic, label: "CSS" },
    { imageUrl: zapierpic, label: "Zapier" },
    { imageUrl: reactpic, label: "React" },
    { imageUrl: awspic, label: "Amazon Web Services" },
    { imageUrl: stripepic, label: "Stripe" },
    { imageUrl: gapic, label: "Google Analytics" },
    { imageUrl: jirapic, label: "Jira" },
    // Add more elements as needed
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: true,
    swipe: true,
    swipeToSlide: true,
    arrows: false,
  };

  return (
    <section id="hero" className="px-8 pt-16">
      <div className="max-w-screen-xl mx-auto flex flex-col max-w-screen-xl mx-auto flex flex-colflex flex-col gap-4">
        <motion.div
          initial={{
            opacity: 0,
            // if odd index card,slide from right instead of left
            x: 50,
          }}
          whileInView={{
            opacity: 1,
            x: 0, // Slide in to its original position
            transition: {
              duration: 1, // Animation duration
            },
          }}
          viewport={{ once: true, amount: 0.3 }}
        >
          <div className="text-white space-y-3">
            <h4 className="font-semibold text-indigo-400">
              About me {`\u2014`}
            </h4>
            <h2 className="font-semibold">Bio</h2>
          </div>
          <div className="text-white">
            <p className="text-stone-300">
              {`Aspiring Web-Agency Founder \u2014 Several years of experience working on large scale SAAS products \u2014 Passionate about bringing new ideas to life \u2014 Someday I'd like to own a farm`}
            </p>
          </div>
        </motion.div>
        <motion.div
          className="flex flex-col gap-4 text-white"
          initial={{
            opacity: 0,
            // if odd index card,slide from right instead of left
            x: 50,
          }}
          whileInView={{
            opacity: 1,
            x: 0, // Slide in to its original position
            transition: {
              duration: 1, // Animation duration
            },
          }}
          viewport={{ once: true, amount: 0.6 }}
        >
          <h2 className="font-semibold">Experience with</h2>
          <Technologies />
          <h4 className="text-white">also...</h4>
          <SecondaryTechnologies />
        </motion.div>
      </div>

      {/* <div className={classes.aboutcontainer}>
        <div className={classes.leftpanel}>
          <div
            className="about-text"
            style={{
              maxWidth: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "26px",
              boxShadow: "4px 8px 38px rgba(0, 0, 0, 0.1)",
              padding: "36px 36px",
              borderRadius: "6px",
            }}
          >
            <div>
              <p className={classes.paragraphtext}>
                I am a dedicated Software Engineer based in Ontario, Canada,
                with a strong passion for developing web applications.{" "}
              </p>
              <p className={classes.paragraphtext}>
                My primary experience is in building web solutions from user
                stories, and building custom integration's between applications.
              </p>
              <p className={classes.paragraphtext}>
                In my free time, I enjoy spending time with my friends/family
                and being outdoors{" "}
              </p>
            </div>
            <div className={classes.gridcontainer}>
              <div className={classes.griditem}>
                <h2 className={classes.gridheading}>Name:</h2>
                <div>Omar Tahir</div>
              </div>
              <div className={classes.griditem}>
                <h2 className={classes.gridheading}>Education:</h2>
                <div>BSC in Computer Science</div>
                <div style={{ fontSize: "12px" }}>
                  University of Western Ontario
                </div>
              </div>
              <div className={classes.griditem}>
                <h2 className={classes.gridheading}>Email:</h2>
                <div>omar.tahir@hotmail.com</div>
              </div>
              <div className={classes.griditem}>
                <h2 className={classes.gridheading}>Socials:</h2>
                <div>LinkedIn</div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.rightpanel}>     
          <PicturePanel />
        </div>
      </div> */}
      {/* <div className={classes.bannercarousel}>
        <div style={{ fontSize: "18px", paddingTop: "16px" }}>
          Experience with
        </div>
        <Slider className={classes.slider} {...settings}>
          {elements.map((element, index) => (
            <div key={index}>
              <img
                src={element.imageUrl}
                alt={element.label}
                className={classes.bannersliderimg}
              />
              <div className={classes.bannersliderimglbl}>{element.label}</div>
            </div>
          ))}
        </Slider>
      </div> */}
    </section>
  );
}

export default AboutSection;
