import React, { useState } from "react";
import {
  Box,
  Typography,
  Divider,
  Grid,
  TextField,
  Button,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { FaNodeJs } from "react-icons/fa";
import { SiExpress } from "react-icons/si";
import { SiAwslambda } from "react-icons/si";
import { SiAmazonapigateway } from "react-icons/si";
import { motion } from "framer-motion";
import { FaReact } from "react-icons/fa";
import { BiLogoMongodb } from "react-icons/bi";
import { PiThumbsUp } from "react-icons/pi";

const techList = [
  {
    id: 1,
    label: "MongoDB",
    icon: BiLogoMongodb,
    color: "text-green-400",
    url: "https://www.mongodb.com/",
  },
  {
    id: 2,
    label: "API Gateway",
    icon: SiAmazonapigateway,
    color: "text-purple-400",
    url: "https://aws.amazon.com/api-gateway/",
  },
  {
    id: 3,
    label: "Lambda",
    icon: SiAwslambda,
    color: "text-orange-500",
    url: "https://aws.amazon.com/pm/lambda/",
  },
  {
    id: 4,
    label: "Node JS",
    icon: FaNodeJs,
    color: "text-green-400",
    url: "https://nodejs.org/en",
  },
  {
    id: 5,
    label: "Express",
    icon: SiExpress,
    color: "text-white",
    url: "https://expressjs.com/",
  },
  {
    id: 6,
    label: "React",
    icon: FaReact,
    color: "text-sky-400",
    url: "https://react.dev/",
  },
];

const OverviewPanel = () => {
  const [submitFeedback, setSubmitFeedback] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [submitLikedFeedback, setSubmitLikedFeedback] = useState(false);
  const [likedFeedbackSubmitted, setLikedFeedbackSubmitted] = useState(false);
  const [likedFeedbackMessage, setLikedFeedbackMessage] = useState("");
  const isScreenLarge = useMediaQuery("(min-width:900px)");
  const isScreenMedium = useMediaQuery("(min-width:600px)");

  const openLink = (link) => {
    window.open(link, "_blank");
  };

  const onSubmitFeedback = (data) => {
    fetch(
      "https://735ravmme6.execute-api.us-east-2.amazonaws.com/sendContactEmail",
      {
        mode: "no-cors",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          senderName: data.name,
          senderEmail: data.email,
          senderPhone: data.phone,
          senderCompany: data.subject,
          senderMessage: data.message,
        }),
      }
    );
  };
  return (
    <Box flexBasis="30%" height="100%" overflow="auto" p={"0px 16px 16px 24px"}>
      <Typography paragraph>
        This project demonstrates how you can create a custom integration with
        over 7,000 other applications in just a few steps
      </Typography>
      <Typography variant="h6" gutterBottom>
        Tech-stack
      </Typography>
      <div className="flex flex-wrap mb-4 items-center justify-center sm:justify-start gap-7 sm:gap-4 max-h-[calc(3*72px)] hide-scrollbar">
        {techList.map((tech) => {
          const IconComponent = tech.icon;
          return (
            <motion.div
              key={tech.id}
              whileHover={{ scale: 1.2 }}
              onHoverStart={(e) => {}}
              onHoverEnd={(e) => {}}
              className="rounded-xl md:rounded-2xl border-4 border-neutral-800 p-3 hover:cursor-pointer"
              onClick={() => openLink(tech.url)}
            >
              <IconComponent
                className={`text-3xl xs:text-3xl sm:text-3xl md:text-3xl lg:text-3xl ${tech.color}`}
              />
            </motion.div>
          );
        })}
      </div>
      <Divider />
      <Typography variant="h6" mt={"16px"} gutterBottom>
        Like the project?
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "8px",
          marginBottom: "16px",
        }}
      >
        <motion.div
          key={"likes-project"}
          whileHover={{ scale: 1.05 }}
          onHoverStart={(e) => {}}
          onHoverEnd={(e) => {}}
          className="flex justify-center gap-1 rounded-sm w-32 md:rounded-2md border-2 border-neutral-800 p-3 hover:cursor-pointer"
          onClick={() => {
            setSubmitLikedFeedback(!submitLikedFeedback);
            setSubmitFeedback(false);
          }}
        >
          <Typography textAlign="center">Yes</Typography>
          <PiThumbsUp
            className={`text-xl xs:text-2xl sm:text-2xl md:text-2xl lg:text-2xl text-green-400 `}
          />
        </motion.div>
        <motion.div
          key={"needs-work-project"}
          whileHover={{ scale: 1.05 }}
          onHoverStart={(e) => {}}
          onHoverEnd={(e) => {}}
          className="flex justify-center gap-1 rounded-sm w-64 md:rounded-2md border-2 border-neutral-800 p-3 hover:cursor-pointer"
          onClick={() => {
            setSubmitFeedback(!submitFeedback);
            setSubmitLikedFeedback(false);
          }}
        >
          <Typography textAlign="center">Could be better</Typography>
        </motion.div>
      </div>
      {submitLikedFeedback && (
        <>
          <div className="sm:col-span-2 mb-4">
            <label
              htmlFor="message"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
            >
              Thank you! How can this project help you?
            </label>
            <textarea
              id="message"
              rows="6"
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="Leave message..."
              onChange={(e) => setLikedFeedbackMessage(e.target.value)}
            ></textarea>
          </div>
          <div className="flex flex-col gap-2 mb-4">
            <button
              type="submit"
              disabled={likedFeedbackSubmitted}
              className={`py-3 px-5 text-sm font-medium text-center text-white rounded-lg sm:w-fit focus:ring-4 focus:outline-none focus:ring-primary-300 ${
                likedFeedbackSubmitted
                  ? "bg-gray-400"
                  : "bg-indigo-400 hover:bg-indigo-500"
              }`}
              onClick={() => {
                setLikedFeedbackSubmitted(true);
                onSubmitFeedback({
                  name: "Zapier Project User Feedback: Liked the project",
                  email: "zapfeedback@email.com",
                  phone: "",
                  company: "",
                  message: likedFeedbackMessage,
                });
              }}
            >
              {likedFeedbackSubmitted ? "Sent" : "Send"}
            </button>
            {likedFeedbackSubmitted && (
              <p className="text-xs text-stone-300">
                Thank you for the feedback!
              </p>
            )}
          </div>
        </>
      )}
      {submitFeedback && (
        <>
          <div className="sm:col-span-2 mb-4">
            <label
              htmlFor="message"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
            >
              What can be improved?
            </label>
            <textarea
              id="message"
              rows="6"
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="Leave feedback..."
              onChange={(e) => setFeedbackMessage(e.target.value)}
            ></textarea>
          </div>
          <div className="flex flex-col gap-2 mb-4">
            <button
              type="submit"
              disabled={feedbackSubmitted}
              className={`py-3 px-5 text-sm font-medium text-center text-white rounded-lg sm:w-fit focus:ring-4 focus:outline-none focus:ring-primary-300 ${
                feedbackSubmitted
                  ? "bg-gray-400"
                  : "bg-indigo-400 hover:bg-indigo-500"
              }`}
              onClick={() => {
                setFeedbackSubmitted(true);
                onSubmitFeedback({
                  name: "Zapier Project User Feedback: Could be better",
                  email: "zapfeedback@email.com",
                  phone: "",
                  company: "",
                  message: feedbackMessage,
                });
              }}
            >
              {feedbackSubmitted ? "Sent" : "Send"}
            </button>
            {feedbackSubmitted && (
              <p className="text-xs text-stone-300">
                Thank you for the feedback!
              </p>
            )}
          </div>
        </>
      )}
      <Typography variant="h6" gutterBottom>
        Questions?
      </Typography>
      <motion.div
        key={"needs-work-project"}
        whileHover={{ scale: 1.05 }}
        onHoverStart={(e) => {}}
        onHoverEnd={(e) => {}}
        className="flex justify-center gap-1 rounded-sm w-36 md:rounded-2md border-2 border-neutral-800 p-3 hover:cursor-pointer"
        onClick={() => (window.location.href = "mailto:doowindev@gmail.com")}
      >
        <Typography textAlign="center">Email</Typography>
      </motion.div>
    </Box>
  );
};

export default OverviewPanel;
