import React from "react";
import ContactForm from "../components/ContactForm";
import classes from "../styles/ContactSection.module.css";
import { motion } from "framer-motion";
import { MdEmail } from "react-icons/md";

function ContactSection() {
  return (
    <section id="contact" className="px-8 pt-16 mb-8">
      <div className="max-w-screen-xl mx-auto flex flex-col gap-4">
        <motion.div
          initial={{
            opacity: 0,
            x: 50,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: true, amount: 0.3 }}
        >
          <div className="text-white space-y-3">
            <h4 className="font-semibold text-indigo-400">
              Contact {`\u2014`}
            </h4>
            <h2 className="font-semibold">Why not say hello?</h2>
          </div>
          <div className="text-white">
            <p className="text-stone-300">
              Every great project begins with a conversation – Reach Out!
            </p>
          </div>
        </motion.div>
        <motion.div
          className="grid gap-4 sm:grid-cols-1 lg:grid-cols-[60%_40%] text-white"
          initial={{
            opacity: 0,

            x: 50,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: true }}
        >
          <ContactForm className="w-full" />
          <div className="flex flex-col justify-center items-center">
            <div className="flex flex-col gap-4 md:w-9/12 lg:w-9/12 bg-neutral-900 p-6 rounded-md justify-center items-center">
              <div className="w-fit rounded-md bg-gray-700 p-2">
                <MdEmail className="text-3xl text-gray-400" />
              </div>
              <h2 className="font-semibold text-white">Email:</h2>
              <p className="text-stone-300 text-center">
                Reach out for general inquiries, partnership opportunities, or
                just to say hello!
              </p>
              <a
                href="mailto:doowindev@gmail.com"
                className="font-semibold text-indigo-400"
              >
                doowindev@gmail.com
              </a>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
}

export default ContactSection;
