import React from "react";
import { Box, Typography } from "@mui/material";

const StepIcon = ({ size, stepNumber }) => {
  return (
    <Box
      width={size ?? 28}
      height={size ?? 28}
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="50%"
      bgcolor="#2E2E2E"
      mt={"2px"}
    >
      <Typography style={{ fontSize: "14px", color: "white" }}>
        {stepNumber}
      </Typography>
    </Box>
  );
};

export default StepIcon;
