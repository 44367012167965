import React, { useState, useRef } from "react";
import {
  Box,
  Typography,
  Divider,
  TextField,
  Button,
  Tooltip,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ZapStep2Pic1 from "./assets/zapier-app-step-2-1.png";
import ZapStep4Pic1 from "./assets/zapier-app-step-4-1.png";
import { TriggerManager } from "./modules/TriggerManager";
import { FaNodeJs } from "react-icons/fa";
import { SiExpress } from "react-icons/si";
import { SiAwslambda } from "react-icons/si";
import { SiAmazonapigateway } from "react-icons/si";
import { motion } from "framer-motion";
import { FaReact } from "react-icons/fa";
import { BiLogoMongodb } from "react-icons/bi";
import { PiThumbsUp } from "react-icons/pi";
import StepIcon from "../../../components/StepIcon";
import { FaArrowUp } from "react-icons/fa";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OverviewPanel from "./components/OverviewPanel";
import NotificationSnackbar from "./components/NotificationSnackbar";

const bookingObject = {
  bookingId: "b54321",
  customer: {
    customerId: "c98765",
    name: "Michael Smith",
    email: "michael.smith@example.com",
    phone: "+1123456789",
  },
  service: {
    serviceId: "s12345",
    serviceName: "Basic Driving Lesson",
    description: "2-hour basic driving lesson with a certified instructor",
  },
  instructor: {
    instructorId: "i87654",
    name: "Laura Brown",
    email: "laura.brown@example.com",
    phone: "+1098765432",
  },
  bookingDetails: {
    date: "2024-08-01",
    startTime: "9:00 AM",
    endTime: "11:00 AM",
    location: "789 Maple St, Metropolis",
    price: 80.0,
    currency: "USD",
  },
  payment: {
    paymentId: "p33445",
    paymentMethod: "Debit Card",
    status: "Paid",
    amount: 80.0,
    currency: "USD",
    transactionDate: "2024-07-10",
  },
  status: "Confirmed",
  notes: "Customer is a beginner and wants to focus on basic maneuvers.",
};

const techList = [
  {
    id: 1,
    label: "MongoDB",
    icon: BiLogoMongodb,
    color: "text-green-400",
    url: "https://www.mongodb.com/",
  },
  {
    id: 2,
    label: "API Gateway",
    icon: SiAmazonapigateway,
    color: "text-purple-400",
    url: "https://aws.amazon.com/api-gateway/",
  },
  {
    id: 3,
    label: "Lambda",
    icon: SiAwslambda,
    color: "text-orange-500",
    url: "https://aws.amazon.com/pm/lambda/",
  },
  {
    id: 4,
    label: "Node JS",
    icon: FaNodeJs,
    color: "text-green-400",
    url: "https://nodejs.org/en",
  },
  {
    id: 5,
    label: "Express",
    icon: SiExpress,
    color: "text-white",
    url: "https://expressjs.com/",
  },
  {
    id: 6,
    label: "React",
    icon: FaReact,
    color: "text-sky-400",
    url: "https://react.dev/",
  },
];

const ZapierAppOverview = () => {
  const [apiKey, setApiKey] = useState("");
  const [clientId, setClientId] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [copied, setCopied] = useState({ apiKey: false, clientId: false });
  const [submitFeedback, setSubmitFeedback] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [submitLikedFeedback, setSubmitLikedFeedback] = useState(false);
  const [likedFeedbackSubmitted, setLikedFeedbackSubmitted] = useState(false);
  const [likedFeedbackMessage, setLikedFeedbackMessage] = useState("");
  const targetRef = useRef(null);
  const isScreenLarge = useMediaQuery("(min-width:900px)");
  const isScreenMedium = useMediaQuery("(min-width:600px)");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [snackSeverity, setSnackSeverity] = useState("success");

  const handleGenerateKeys = () => {
    const apikey = generateSKey();
    const clientid = generateClientId();
    setApiKey(apikey);
    setClientId(clientid);
    setButtonDisabled(true);
    fetch(
      "https://f82145q0e5.execute-api.us-east-2.amazonaws.com/devot/IntegrationManager",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          client_id: clientid,
          secret_key: apikey,
        }),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok", response);
        }
        return response.json();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleCopy = (keyType) => {
    navigator.clipboard.writeText(keyType === "apiKey" ? apiKey : clientId);
    setCopied({ ...copied, [keyType]: true });
    setTimeout(() => setCopied({ ...copied, [keyType]: false }), 2000);
  };

  //outputs 24 character hexstring
  const generateClientId = () => {
    const characters = "0123456789abcdef";
    let result = "";
    for (let i = 0; i < 24; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const handleTriggerBooking = async () => {
    if (!clientId || !apiKey) {
      handleOpenSnackBar(
        "Please generate keys in Step 3, and create a Zap before testing",
        "warning"
      );
      return;
    }

    await TriggerManager(
      bookingObject,
      clientId,
      apiKey,
      "new-booking-zapier",
      handleOpenSnackBar
    );
  };

  //outputs 36 character id with a stest_ prefix
  const generateSKey = () => {
    const characters =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let result = "stest_";
    for (let i = 0; i < 36; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const openLink = (link) => {
    window.open(link, "_blank");
  };

  const scrollToTarget = () => {
    targetRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const onSubmitFeedback = (data) => {
    fetch(
      "https://735ravmme6.execute-api.us-east-2.amazonaws.com/sendContactEmail",
      {
        mode: "no-cors",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          senderName: data.name,
          senderEmail: data.email,
          senderPhone: data.phone,
          senderCompany: data.subject,
          senderMessage: data.message,
        }),
      }
    );
  };

  const handleOpenSnackBar = (message, severity) => {
    setOpenSnackbar(true);
    setSnackMessage(message);
    setSnackSeverity(severity);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <Box
      display="flex"
      width="100%"
      height={!isScreenMedium ? "88.6vh" : !isScreenLarge ? "87.7vh" : "93vh"}
    >
      <NotificationSnackbar
        open={openSnackbar}
        severity={snackSeverity}
        message={snackMessage}
        onClose={handleCloseSnackbar}
      />
      {isScreenLarge && (
        <Box
          flexBasis="30%"
          height="100%"
          overflow="auto"
          p={"16px 16px 16px 24px"}
        >
          <Typography variant="caption" color={"#a3a3a3"}>
            {`projects > Zapier Demo`}
          </Typography>
          <Typography variant="h6" mt={3} gutterBottom>
            Overview
          </Typography>
          <Typography paragraph>
            This project demonstrates how you can create a custom integration
            with over 7,000 other applications in just a few steps
          </Typography>
          <Typography variant="h6" gutterBottom>
            Tech-stack
          </Typography>
          <div className="flex flex-wrap mb-4 items-center justify-center sm:justify-start gap-7 sm:gap-4 max-h-[calc(3*72px)] hide-scrollbar">
            {techList.map((tech) => {
              const IconComponent = tech.icon;
              return (
                <motion.div
                  key={tech.id}
                  whileHover={{ scale: 1.2 }}
                  onHoverStart={(e) => {}}
                  onHoverEnd={(e) => {}}
                  className="rounded-xl md:rounded-2xl border-4 border-neutral-800 p-3 hover:cursor-pointer"
                  onClick={() => openLink(tech.url)}
                >
                  <IconComponent
                    className={`text-3xl xs:text-3xl sm:text-3xl md:text-3xl lg:text-3xl ${tech.color}`}
                  />
                </motion.div>
              );
            })}
          </div>
          <Divider />
          <Typography variant="h6" mt={"16px"} gutterBottom>
            Like the project?
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "8px",
              marginBottom: "16px",
            }}
          >
            <motion.div
              key={"likes-project"}
              whileHover={{ scale: 1.05 }}
              onHoverStart={(e) => {}}
              onHoverEnd={(e) => {}}
              className="flex justify-center gap-1 rounded-sm w-32 md:rounded-2md border-2 border-neutral-800 p-3 hover:cursor-pointer"
              onClick={() => {
                setSubmitLikedFeedback(!submitLikedFeedback);
                setSubmitFeedback(false);
              }}
            >
              <Typography textAlign="center">Yes</Typography>
              <PiThumbsUp
                className={`text-xl xs:text-2xl sm:text-2xl md:text-2xl lg:text-2xl text-green-400 `}
              />
            </motion.div>
            <motion.div
              key={"needs-work-project"}
              whileHover={{ scale: 1.05 }}
              onHoverStart={(e) => {}}
              onHoverEnd={(e) => {}}
              className="flex justify-center gap-1 rounded-sm w-64 md:rounded-2md border-2 border-neutral-800 p-3 hover:cursor-pointer"
              onClick={() => {
                setSubmitFeedback(!submitFeedback);
                setSubmitLikedFeedback(false);
              }}
            >
              <Typography textAlign="center">Could be better</Typography>
            </motion.div>
          </div>
          {submitLikedFeedback && (
            <>
              <div className="sm:col-span-2 mb-4">
                <label
                  htmlFor="message"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                >
                  Thank you! How can this project help you?
                </label>
                <textarea
                  id="message"
                  rows="6"
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Leave message..."
                  onChange={(e) => setLikedFeedbackMessage(e.target.value)}
                ></textarea>
              </div>
              <div className="flex flex-col gap-2 mb-4">
                <button
                  type="submit"
                  disabled={likedFeedbackSubmitted}
                  className={`py-3 px-5 text-sm font-medium text-center text-white rounded-lg sm:w-fit focus:ring-4 focus:outline-none focus:ring-primary-300 ${
                    likedFeedbackSubmitted
                      ? "bg-gray-400"
                      : "bg-indigo-400 hover:bg-indigo-500"
                  }`}
                  onClick={() => {
                    setLikedFeedbackSubmitted(true);
                    onSubmitFeedback({
                      name: "Zapier Project User Feedback: Liked the project",
                      email: "zapfeedback@email.com",
                      phone: "",
                      company: "",
                      message: likedFeedbackMessage,
                    });
                  }}
                >
                  {likedFeedbackSubmitted ? "Sent" : "Send"}
                </button>
                {likedFeedbackSubmitted && (
                  <p className="text-xs text-stone-300">
                    Thank you for the feedback!
                  </p>
                )}
              </div>
            </>
          )}
          {submitFeedback && (
            <>
              <div className="sm:col-span-2 mb-4">
                <label
                  htmlFor="message"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                >
                  What can be improved?
                </label>
                <textarea
                  id="message"
                  rows="6"
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Leave feedback..."
                  onChange={(e) => setFeedbackMessage(e.target.value)}
                ></textarea>
              </div>
              <div className="flex flex-col gap-2 mb-4">
                <button
                  type="submit"
                  disabled={feedbackSubmitted}
                  className={`py-3 px-5 text-sm font-medium text-center text-white rounded-lg sm:w-fit focus:ring-4 focus:outline-none focus:ring-primary-300 ${
                    feedbackSubmitted
                      ? "bg-gray-400"
                      : "bg-indigo-400 hover:bg-indigo-500"
                  }`}
                  onClick={() => {
                    setFeedbackSubmitted(true);
                    onSubmitFeedback({
                      name: "Zapier Project User Feedback: Could be better",
                      email: "zapfeedback@email.com",
                      phone: "",
                      company: "",
                      message: feedbackMessage,
                    });
                  }}
                >
                  {feedbackSubmitted ? "Sent" : "Send"}
                </button>
                {feedbackSubmitted && (
                  <p className="text-xs text-stone-300">
                    Thank you for the feedback!
                  </p>
                )}
              </div>
            </>
          )}
          <Typography variant="h6" gutterBottom>
            Questions?
          </Typography>
          <motion.div
            key={"needs-work-project"}
            whileHover={{ scale: 1.05 }}
            onHoverStart={(e) => {}}
            onHoverEnd={(e) => {}}
            className="flex justify-center gap-1 rounded-sm w-36 md:rounded-2md border-2 border-neutral-800 p-3 hover:cursor-pointer"
            onClick={() =>
              (window.location.href = "mailto:doowindev@gmail.com")
            }
          >
            <Typography textAlign="center">Email</Typography>
          </motion.div>
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="column"
        flexBasis={isScreenLarge ? "70%" : "100%"}
        height="100%"
        gap={4}
        overflow="auto"
        p={2}
        pl={4}
        pt={isScreenLarge ? "36px" : "0px"}
        position={"relative"}
      >
        {!isScreenLarge && (
          <Accordion
            sx={{
              backgroundColor: "#0A0A0A",
              position: "fixed",
              top: !isScreenMedium ? 56 : 64,
              left: 0,
              right: 0,
              zIndex: 9999,
            }}
            style={{ borderRadius: "0px" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography>Overview</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <OverviewPanel />
            </AccordionDetails>
          </Accordion>
        )}
        <Box display="flex" flexDirection={"column"} gap={1.5} ref={targetRef}>
          <Typography variant="h3" mt={!isScreenLarge && "38px"}>
            Zapier Demo
          </Typography>
          <Typography paragraph>
            Try Zapier yourself with an interactive demo
          </Typography>
        </Box>
        <Box>
          <Box display="flex" alignItems="flex-start">
            <StepIcon stepNumber={1} />
            <Box flexBasis={"85%"} ml={2}>
              <Typography variant="h6" gutterBottom>
                Create a Zap
              </Typography>
              <Typography paragraph>
                Visit{" "}
                <a
                  className="text-blue-500"
                  href="https://zapier.com/developer/public-invite/207063/5565c849cc4338e113a79f7c2d855f68/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Zapier
                </a>{" "}
                and create a new Zap
              </Typography>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#FF510A",
                  ":hover": { backgroundColor: "#e44c0d" },
                  padding: "10px 24px 10px 24px",
                }}
                onClick={() =>
                  openLink(
                    "https://zapier.com/developer/public-invite/207063/5565c849cc4338e113a79f7c2d855f68/"
                  )
                }
              >
                Accept Invite & Build a Zap
              </Button>
            </Box>
          </Box>
        </Box>

        <Box>
          <Box display="flex" alignItems="flex-start">
            <StepIcon stepNumber={2} />
            <Box flexBasis={"85%"} ml={2}>
              <Typography variant="h6" gutterBottom>
                Select an App & Event
              </Typography>
              <Typography paragraph>
                In the list of “Triggers” find Devot - then select “New Booking”
                as the Event
              </Typography>
              <Box
                component="img"
                sx={{
                  width: "100%", // Make the width 100% of its container
                  maxWidth: "565.78px",
                  height: "auto", // Keep the aspect ratio of the image
                }}
                src={ZapStep2Pic1}
                alt="Zap Apps"
                borderRadius={4}
              />
            </Box>
          </Box>
        </Box>

        <Box>
          <Box display="flex" alignItems="flex-start">
            <StepIcon stepNumber={3} />
            <Box flexBasis={"85%"} ml={2}>
              <Typography variant="h6" gutterBottom>
                Connect your account
              </Typography>
              <Typography paragraph>
                Generate a new Client Key & Secret Key to authenticate your
                ‘account’
              </Typography>
              <Box
                sx={{
                  borderRadius: "8px",
                  border: "1px solid darkgray",
                  p: 2,
                }}
                className="w-full"
              >
                <Typography variant="caption" gutterBottom>
                  Client ID
                </Typography>
                <Tooltip
                  title={copied.clientId ? "Copied" : ""}
                  placement="top"
                  arrow
                >
                  <TextField
                    fullWidth
                    size="small"
                    value={clientId}
                    disabled={!clientId}
                    onClick={() => handleCopy("clientId")}
                    sx={{
                      color: "white",
                      backgroundColor: "#404040",
                      borderRadius: "4px",
                      marginTop: "8px",
                      marginBottom: "8px",
                    }}
                  />
                </Tooltip>

                <Typography variant="caption" gutterBottom>
                  API Key
                </Typography>
                <Tooltip
                  title={copied.apiKey ? "Copied" : ""}
                  placement="top"
                  arrow
                >
                  <TextField
                    fullWidth
                    size="small"
                    value={apiKey}
                    disabled={!apiKey}
                    onClick={() => handleCopy("apiKey")}
                    sx={{
                      color: "white",
                      backgroundColor: "#404040",
                      borderRadius: "4px",
                      marginTop: "8px",
                    }}
                  />
                </Tooltip>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleGenerateKeys}
                  disabled={buttonDisabled}
                  sx={{
                    backgroundColor: "#FF510A",
                    ":hover": { backgroundColor: "#e44c0d" },
                    ":disabled": { backgroundColor: "#171717" },
                    padding: "10px 24px 10px 24px",
                    mt: 2,
                  }}
                >
                  Generate Keys
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box>
          <Box display="flex" alignItems="flex-start">
            <StepIcon stepNumber={4} />
            <Box flexBasis={"85%"} ml={2}>
              <Typography variant="h6" gutterBottom>
                Select an Action
              </Typography>
              <Typography paragraph>
                An ‘Action’ is performed when the Zap runs - for example, you
                could “Create an Event” in your Google Calendar
              </Typography>
              <Box
                component="img"
                sx={{
                  width: "100%", // Make the width 100% of its container
                  maxWidth: "565.78px", // Limit the maximum width to 40% of its container
                  height: "auto", // Keep the aspect ratio of the image
                }}
                src={ZapStep4Pic1}
                alt="Zap Apps"
                borderRadius={4}
              />
            </Box>
          </Box>
        </Box>

        <Box>
          <Box display="flex" alignItems="flex-start">
            <StepIcon stepNumber={5} />
            <Box flexBasis={"85%"} ml={2}>
              <Typography variant="h6" gutterBottom>
                Test your Zap{" "}
              </Typography>
              <Typography paragraph>
                For this example, a new booking for a ‘Driving Instructor
                School’ will trigger your Zap - the following data will be sent
              </Typography>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#FF510A",
                  ":hover": { backgroundColor: "#e44c0d" },
                  padding: "10px 24px 10px 24px",
                  marginBottom: "16px",
                }}
                onClick={handleTriggerBooking}
              >
                Trigger New Booking{" "}
              </Button>
              <Box
                sx={{
                  borderRadius: "8px",
                  border: "1px solid darkgray",
                  p: 2,
                  maxWidth: "600px",
                }}
                className="w-full"
              >
                <pre
                  style={{
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                    fontSize: "12px",
                  }}
                >
                  <code>
                    {JSON.stringify(bookingObject, null, 2)
                      .split("\n")
                      .map((line, index) => (
                        <div key={index} style={{ lineHeight: "1.5em" }}>
                          <span style={{ color: "white" }}>
                            {line.replace(/"(.*?)":/g, '"$1":')}
                          </span>
                        </div>
                      ))}
                  </code>
                </pre>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display={"flex"} justifyContent={"flex-end"}>
          <motion.div
            key={"needs-work-project"}
            whileHover={{ scale: 1.05 }}
            onHoverStart={(e) => {}}
            onHoverEnd={(e) => {}}
            className="flex justify-center items-center gap-2 rounded-sm w-fit px-4 md:rounded-2md border-2 border-neutral-800 p-3 hover:cursor-pointer"
            onClick={() => scrollToTarget()}
          >
            <Typography textAlign="center">Back to top</Typography>
            <FaArrowUp color="white" />
          </motion.div>
        </Box>
      </Box>
    </Box>
  );
};

export default ZapierAppOverview;
