// MainPage.js
import React, { useEffect, useState } from "react";
import FloatingHeader from "../components/FloatingHeader";
//import BackgroundImg from "../images/subtle-prism.jpg";
import classes from "../pages/MainPage.module.css";
import HomeSection from "../sections/HomeSection";
import AboutSection from "../sections/AboutSection";
import PortfolioSection from "../sections/PortfolioSection";
import ContactForm from "../components/ContactForm";
import ContactSection from "../sections/ContactSection";
import Header from "../components/Header";

function MainPage() {
  const [showFixedHeader, setShowFixedHeader] = useState(false);
  const [minHeight, setMinHeight] = useState(
    /* window.innerHeight - 180 */ 660
  );

  useEffect(() => {
    const handleScroll = () => {
      // Calculate the position where you want to show the fixed header
      const scrollPositionToShowFixedHeader = 50; // Adjust this value as needed
      const firstSection = document.getElementById("home");

      if (!firstSection) {
        console.log("home not found");
        return;
      } // Return if the first section is not found

      const firstSectionBottom =
        firstSection.offsetTop + firstSection.clientHeight;

      // Check if the user has scrolled past the first section
      if (
        window.scrollY >
        firstSectionBottom - scrollPositionToShowFixedHeader
      ) {
        setShowFixedHeader(true);
      } else {
        setShowFixedHeader(false);
      }
    };

    // Add event listeners
    window.addEventListener("scroll", handleScroll);
    /* window.addEventListener("resize", handleResize); */

    // Clean up the event listeners when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
      /*  window.removeEventListener("resize", handleResize); */
    };
  }, []);

  return (
    <>
      <div className="absolute top-0 z-[-2] h-screen w-screen bg-neutral-950 bg-[radial-gradient(ellipse_80%_80%_at_50%_-20%,rgba(120,119,198,0.3),rgba(255,255,255,0))]"></div>
      <FloatingHeader showFixedHeader={showFixedHeader} />
      <main>
        <HomeSection classes={classes} />
        <AboutSection />
        <PortfolioSection />
        <ContactSection />
        <footer className={classes.footer}>
          <div>&copy; 2024 Devot</div>
        </footer>

        {/* ... (other sections) */}
      </main>
    </>
  );
}

export default MainPage;
