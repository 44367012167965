import React from "react";

const FilterButtonGroup = ({
  filterOptions,
  selectedFilter,
  setSelectedFilter,
}) => {
  return (
    <div className="flex flex-wrap justify-center gap-4 text-white p-2 w-full max-w-2xl">
      {filterOptions.map((filter) => (
        <button
          key={filter.id}
          title={filter.label}
          className={`flex-grow rounded-2xl border-2 min-w-16 border-neutral-800 text-stone-300 p-1 hover:cursor-pointer hover:bg-indigo-400 hover:text-white ${
            filter.label === selectedFilter ? "bg-indigo-400 text-white" : ""
          }`}
          onClick={() => setSelectedFilter(filter.label)}
        >
          <span className="text-sm font-bold capitalize">{filter.label}</span>
        </button>
      ))}
    </div>
  );
};

export default FilterButtonGroup;
