import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import MainPage from "./pages/MainPage";
import { Grid } from "@mui/material";
import { motion } from "framer-motion";
import ProjectPage from "./pages/ProjectPage";
import "./styles/scrollbarstyles.css";

// Portfolio component
const Portfolio = () => {
  return <div className="text-white">Hello preojects</div>;
};

// Contact component
const Contact = () => (
  <div style={{ marginTop: "160px" }}>
    <h2>Contact Me</h2>
    {/* Content for the contact page */}
  </div>
);

// App component
const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="/projects" element={<Navigate to="/#portfolio" />} />
      <Route path="/projects/:projectName" element={<ProjectPage />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  </Router>
);

export default App;
