/**
 * Function to trigger all hooks associated with the id and hookName
 * @param {Object} triggerBody - The data to be sent to an endpoint
 * @param {string} id - Used to retrieve integration data,
 * @param {string} apikey - Used to retrieve integration data
 * @param {string} hookName - Used to reference the appropriate hook
 * @returns {bool}
 */
async function TriggerManager(triggerBody, id, apikey, hookName, openSnackbar) {
  //function to send data to a list of urls
  function invokeTrigger(urlList, data) {
    let urls = urlList;
    for (let i = 0; i < urls.length; i++) {
      fetch(urls[i], {
        mode: "no-cors",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((data) => {
          console.log("Performed hook for url", urls[i]);
        })
        .catch((error) => {
          console.error("Error performing hook for url", urls[i], error);
        });
    }
  }

  try {
    fetch(
      `https://f82145q0e5.execute-api.us-east-2.amazonaws.com/devot/IntegrationManager/hooks?client_id=${id}&ak=${apikey}&hn=${hookName}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw ("Network response was not ok", response);
        }
        return response.json();
      })
      .then((data) => {
        if (data.hooks !== undefined) {
          invokeTrigger(data.hooks, triggerBody);
          if (openSnackbar)
            openSnackbar("Success: Zap has been triggered", "success");
        } else {
          if (openSnackbar)
            openSnackbar(
              "Error: Hook not found, please make sure your zap is active",
              "error"
            );
        }
      })
      .catch((error) => {
        if (openSnackbar)
          openSnackbar("Error: Unable to find user account", "error");
      });
  } catch (e) {
    console.log("error in getting documents", e);
    if (openSnackbar)
      openSnackbar("Error: Unable to find user account", "error");
  }
}

export { TriggerManager };
