import React, { useState } from "react";
import ProjectCard from "../components/ProjectCard";
import zapierpic from "../images/ot-logo.png";
import zapHeader from "../images/zapier-project-header.jpg";
import classes from "../styles/PortfolioSection.module.css";
import { motion } from "framer-motion";
import { TbBrandZapier } from "react-icons/tb";
import { GrIntegration } from "react-icons/gr";
import { GrSchedules } from "react-icons/gr";
import { MdOutlineShoppingCartCheckout } from "react-icons/md";
import { IoShareSocial } from "react-icons/io5";
import FilterButtonGroup from "../components/FilterButtonGroup";

const PortfolioSection = () => {
  const [selectedFilter, setSelectedFilter] = useState("all");
  const filterOptions = [
    { id: 0, label: "all" },
    { id: 1, label: "landing page" },
    { id: 2, label: "AI" },
    { id: 3, label: "integrations" },
    { id: 4, label: "SaaS" },
  ];

  const projectsData = [
    {
      id: 1,
      title: "Zapier Integration",
      description:
        "Transform Workflows with Zapier: An Integration for Future SaaS",
      img: zapHeader,
      url: "https://zapier.com/",
      tags: ["integrations", "SaaS"],
      pathName: "zapier",
    },
    /* {
      id: 2,
      title: "Integration system",
      description:
        "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
      img: <GrIntegration />,
      url: "https://zapier.com/",
      tags: ["integrations"],
    },
    {
      id: 3,
      title: "Recurring Bookings Scheduling system",
      description:
        "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s ",
      img: <GrSchedules />,
      url: "https://zapier.com/",
      tags: ["integrations"],
    },
    {
      id: 4,
      title: "Self-Serve Subscription Checkout",
      description:
        "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
      img: <MdOutlineShoppingCartCheckout />,
      url: "https://zapier.com/",
      tags: ["integrations"],
    },
    {
      id: 5,
      title: "Social Media AI Content-Generator",
      description:
        "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
      img: <IoShareSocial />,
      url: "https://zapier.com/",
      tags: ["integrations"],
    }, */
    /*
    {
      id: 6,
      title: "zapier",
      description: "This is a description",
      img: zapierpic,
      url: "https://zapier.com/",
      tags: ["integrations"],
    }, */
  ];

  const filteredProjects =
    selectedFilter === "all"
      ? projectsData
      : projectsData.filter((project) => project.tags.includes(selectedFilter));

  return (
    <section id="portfolio" className="px-8 pt-16">
      <div className="max-w-screen-xl mx-auto flex flex-col max-w-screen-xl mx-auto flex flex-colflex flex-col gap-4">
        <motion.div
          initial={{
            opacity: 0,
            x: 50,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: true, amount: 0.6 }}
        >
          <div className="text-white space-y-3">
            <h4 className="font-semibold text-indigo-400">
              Portfolio {`\u2014`}
            </h4>
            <h2 className="font-semibold">My Work and Creations</h2>
          </div>
        </motion.div>

        <motion.div
          initial={{
            opacity: 0,
            x: 50,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: true }}
          className="flex flex-col justify-center flex-col gap-4 items-center"
        >
          <FilterButtonGroup
            filterOptions={filterOptions}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
          />
          <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {filteredProjects.map((project, index) => (
              <motion.div
                initial={{
                  opacity: 0,
                  x: 50,
                }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 1,
                  },
                }}
                viewport={{ once: true, amount: 0.2 }}
                key={index}
                className="flex justify-center items-center"
              >
                <ProjectCard project={project} />
              </motion.div>
            ))}
          </div>
          {filteredProjects.length === 0 && (
            <h2 className="text-neutral-600">More projects coming soon!</h2>
          )}
        </motion.div>
      </div>
    </section>
  );
};

export default PortfolioSection;
