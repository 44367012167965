import React, { useState, useEffect, useRef } from "react";
import classes from "../styles/Header.module.css";
import otlogo from "../images/pot-logo.png";
import { Link } from "react-scroll";
import MenuIcon from "@mui/icons-material/Menu";
import MenuSvg from "../assets/MenuSvg";
import { Grid } from "@mui/material";
import { disablePageScroll, enablePageScroll } from "scroll-lock";
import { motion } from "framer-motion";

function Header(props) {
  const [activeSection, setActiveSection] = useState("home");
  const [scrolling, setScrolling] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false); // State to manage mobile menu visibility
  const mobileMenuRef = useRef();
  // Function to handle scroll events
  const handleScroll = () => {
    const aboutSection = document.getElementById("hero");
    const portfolioSection = document.getElementById("portfolio");
    const contactSection = document.getElementById("contact");
    const scrollPosition = window.scrollY;
    const offSet = 72;

    if (!scrolling) {
      if (
        scrollPosition >= aboutSection.offsetTop &&
        scrollPosition < portfolioSection.offsetTop - offSet
      ) {
        setActiveSection("hero");
      } else if (
        scrollPosition >= portfolioSection.offsetTop - offSet &&
        scrollPosition < contactSection.offsetTop - offSet
      ) {
        setActiveSection("portfolio");
      } else if (scrollPosition >= contactSection.offsetTop - offSet) {
        setActiveSection("contact");
      } else {
        setActiveSection("home");
      }
    }
  };

  const handleToggleMobileMenu = (event) => {
    event.stopPropagation();
    if (mobileMenuOpen) {
      setMobileMenuOpen(false);
      enablePageScroll();
    } else {
      setMobileMenuOpen(true);
      disablePageScroll();
    }
  };

  const handleOutsideClick = () => {
    if (mobileMenuOpen) {
      setMobileMenuOpen(false);
      enablePageScroll();
    }
  };

  useEffect(() => {
    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("click", handleOutsideClick);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("click", handleOutsideClick);
    };
  });

  return (
    <Grid className={`${classes.header} ${scrolling ? classes.sticky : ""} `}>
      <div className={classes.leftsection}>
        <img src={otlogo} className={classes.logo} alt="Website Logo" />
      </div>
      {/* Mobile Menu Button */}
      <button
        className={classes.mobileMenuButton}
        onClick={handleToggleMobileMenu}
      >
        {/* <MenuIcon /> */}
        <MenuSvg openNavigation={mobileMenuOpen} />
      </button>
      {/* Navigation Links */}
      <motion.div
        ref={mobileMenuRef}
        className={`${classes.rightsection} ${
          mobileMenuOpen ? classes.showMobileMenu : ""
        } ${mobileMenuOpen && !props.showFixedHeader ? classes.iasc : ""}`}
      >
        <Link
          to="home"
          smooth={true}
          duration={600}
          className={props.onHomePage ? classes.active : classes.link}
          onClick={() => {
            handleOutsideClick();
          }}
        >
          Home
        </Link>
        <Link
          to={"hero"}
          smooth={true}
          duration={600}
          offset={-20}
          spy={true}
          hashSpy={true}
          className={
            activeSection === "hero" ||
            (activeSection === "home" && !props.onHomePage)
              ? classes.active
              : classes.link
          }
          onClick={() => {
            handleOutsideClick();
          }}
        >
          About Me
        </Link>
        <Link
          to="portfolio"
          smooth={true}
          duration={600}
          offset={-20}
          spy={true}
          hashSpy={true}
          className={
            activeSection === "portfolio" ? classes.active : classes.link
          }
          onClick={() => {
            handleOutsideClick();
          }}
        >
          Portfolio
        </Link>
        <Link
          to="contact"
          smooth={true}
          duration={600}
          offset={-20}
          spy={true}
          hashSpy={true}
          className={
            activeSection === "contact" ? classes.active : classes.link
          }
          onClick={() => {
            handleOutsideClick();
          }}
        >
          Contact
        </Link>
      </motion.div>
    </Grid>
  );
}

export default Header;
