import React from "react";
import { FaNodeJs } from "react-icons/fa";
import { FaCss3 } from "react-icons/fa";
import { RiTailwindCssFill } from "react-icons/ri";
import { SiZapier } from "react-icons/si";
import { SiGoogletagmanager } from "react-icons/si";
import { SiExpress } from "react-icons/si";
import { IoLogoJavascript } from "react-icons/io5";
import { FaHtml5 } from "react-icons/fa";
import { SiAwslambda } from "react-icons/si";
import { SiAmazonapigateway } from "react-icons/si";
import { motion } from "framer-motion";
import { FaReact } from "react-icons/fa";
import dynamoDBpng from "../images/dynamo-db.png";
import "../styles/tech.css";

const SiDynamoDB = () => {
  return <img alt="dynamoDB" src={dynamoDBpng} className="icon" />;
};

const technologiesList = [
  {
    id: 0,
    label: "React",
    icon: FaReact,
    color: "text-sky-400",
    url: "https://react.dev/",
  },
  {
    id: 1,
    label: "JavaScript",
    icon: IoLogoJavascript,
    color: "text-yellow-400",
    url: "https://www.javascript.com/",
  },
  {
    id: 2,
    label: "Node JS",
    icon: FaNodeJs,
    color: "text-green-400",
    url: "https://nodejs.org/en",
  },
  {
    id: 3,
    label: "Express",
    icon: SiExpress,
    color: "text-white",
    url: "https://expressjs.com/",
  },
  {
    id: 4,
    label: "HTML",
    icon: FaHtml5,
    color: "text-orange-500",
    url: "https://html.com/",
  },
  {
    id: 5,
    label: "Lambda",
    icon: SiAwslambda,
    color: "text-orange-500",
    url: "https://aws.amazon.com/pm/lambda/",
  },
  {
    id: 6,
    label: "DynamoDB",
    icon: SiDynamoDB,
    color: "text-sky-400",
    url: "https://aws.amazon.com/dynamodb/",
  },
  {
    id: 7,
    label: "API Gateway",
    icon: SiAmazonapigateway,
    color: "text-purple-400",
    url: "https://aws.amazon.com/api-gateway/",
  },
];

const Technologies = () => {
  const openLink = (link) => {
    window.open(link, "_blank");
  };

  return (
    <div className="flex flex-wrap items-center justify-center sm:justify-start gap-7 sm:gap-4">
      {technologiesList.map((tech) => {
        const IconComponent = tech.icon;
        return (
          <motion.div
            key={tech.id}
            whileHover={{ scale: 1.2 }}
            onHoverStart={(e) => {}}
            onHoverEnd={(e) => {}}
            className="rounded-2xl border-4 border-neutral-800 p-3 hover:cursor-pointer"
            onClick={() => openLink(tech.url)}
          >
            <IconComponent
              className={`text-5xl xs:text-3xl sm:text-3xl md:text-5xl lg:text-5xl ${tech.color}`}
            />
          </motion.div>
        );
      })}
    </div>
  );
};

export default Technologies;
