import React, { useState } from "react";
import Header from "../components/Header";
import headshot from "../images/software_illustration.svg";
import { Grid } from "@mui/material";
import { motion } from "framer-motion";
import resumeIcon from "./../images/curriculum-vitae.png";
import linkedinIcon from "./../images/linkedin-logo.png";
import resumePDF from "./../assets/resume.pdf";
import { FaLinkedin } from "react-icons/fa";

const blockAppear = {
  hidden: { opacity: 0, x: 100 },
  visible: (index) => ({
    opacity: [0, 0.8, 1],
    x: [100, -5, 0],
    transition: {
      duration: 0.8,
      times: [0, 0.8, 1],
      ease: "easeInOut",
      delay: 0.1 + index * 0.3,
    },
  }),
};

function HomeSection({ minHeight, classes }) {
  const blocksTop = [
    [
      { className: "w-48 h-3 bg-gradient-to-r from-sky-500 to-teal-400" },
      { className: "w-32 h-3 bg-gray-400" },
      { className: "w-3 h-3 bg-gray-400 rounded-full" },
    ],
    [{ className: "w-96 h-3 bg-gradient-to-r from-fuchsia-300 to-indigo-200" }],
    [
      { className: "w-32 h-3 bg-gradient-to-r from-orange-200 to-red-400" },
      { className: "w-3 h-3 bg-gray-400" },
      { className: "w-24 h-3 bg-gray-400" },
      { className: "w-48 h-3 bg-gradient-to-r from-sky-500 to-teal-400" },
    ],
    [
      { className: "w-52 h-3 bg-gradient-to-r from-sky-500 to-teal-400" },
      { className: "w-32 h-3 bg-gray-400" },
      { className: "w-3 h-3 bg-gray-400 rounded-full" },
      { className: "w-40 h-3 bg-gradient-to-r from-orange-200 to-red-400" },
    ],
    [
      { className: "w-36 h-3 bg-gradient-to-r from-sky-500 to-teal-400" },
      { className: "w-3 h-3 bg-gray-400" },
      { className: "w-28 h-3 bg-gray-400" },
      { className: "w-40 h-3 bg-gradient-to-r from-fuchsia-300 to-indigo-200" },
    ],
  ];

  const blocksBottom = [
    [
      { className: "w-60 h-3 bg-gradient-to-r from-orange-200 to-red-400" },
      { className: "w-3 h-3 bg-gray-400 rounded-full" },
    ],
    [
      { className: "w-48 h-3 bg-gradient-to-r from-fuchsia-300 to-indigo-200" },
      { className: "w-24 h-3 bg-gradient-to-r from-sky-500 to-teal-400" },
      { className: "w-60 h-3 bg-gradient-to-r from-fuchsia-300 to-indigo-200" },
    ],
    [
      { className: "w-64 h-3 bg-gradient-to-r from-sky-500 to-teal-400" },
      { className: "w-28 h-3 bg-gradient-to-r from-fuchsia-300 to-indigo-200" },
    ],
    [{ className: "w-72 h-3 bg-gradient-to-r from-orange-200 to-red-400" }],
    [
      { className: "w-24 h-3 bg-gradient-to-r from-fuchsia-300 to-indigo-200" },
      { className: "w-3 h-3 bg-gray-400" },
      { className: "w-36 h-3 bg-gray-400" },
      { className: "w-16 h-3 bg-gradient-to-r from-sky-500 to-teal-400" },
    ],
  ];

  const openLink = (link) => {
    window.open(link, "_blank");
  };

  return (
    <section
      id="home"
      className={classes.sectionWithBackground}
      /* style={{
        minHeight: minHeight,
      }} */
    >
      {/* <div className="absolute top-0 z-[-2] h-screen w-screen bg-neutral-950 bg-[radial-gradient(ellipse_80%_80%_at_50%_-20%,rgba(120,119,198,0.3),rgba(255,255,255,0))]"></div> */}
      <Header onHomePage={true} />
      <Grid container gap={4} marginTop={"4rem"} maxWidth={"1280px"}>
        <Grid container gap={1} alignItems="center">
          {blocksTop.map((group, groupIndex) => (
            <Grid
              container
              item
              gap={1}
              key={groupIndex}
              marginLeft={groupIndex === 4 && 4}
            >
              {group.map((item, index) => (
                <motion.div
                  key={index}
                  className={`${item.className} flex items-center justify-center`}
                  variants={blockAppear}
                  initial="hidden"
                  animate="visible"
                  custom={index}
                />
              ))}
            </Grid>
          ))}
          <Grid
            container
            item
            flexDirection="column"
            gap={1}
            sx={{ margin: "1rem 0rem 1rem 2rem" }}
          >
            <motion.h1
              variants={blockAppear}
              initial="hidden"
              animate="visible"
              custom={1.5}
              className="text-4xl font-bold text-slate-50"
            >
              Omar Tahir
            </motion.h1>
            <motion.h2
              variants={blockAppear}
              initial="hidden"
              animate="visible"
              custom={1.5}
              className="text-xl font-medium text-slate-50"
            >
              Fullstack Software Developer
            </motion.h2>
          </Grid>
          {blocksBottom.map((group, groupIndex) => (
            <Grid container item gap={1} key={groupIndex}>
              {group.map((item, index) => (
                <motion.div
                  key={index}
                  className={`${item.className} flex items-center justify-center`}
                  variants={blockAppear}
                  initial="hidden"
                  animate="visible"
                  custom={index}
                />
              ))}
            </Grid>
          ))}
        </Grid>
        <Grid container item flexDirection="row" gap={1}>
          {/* <a
            href="https://www.linkedin.com/in/omar-tahir-478488178"
            target="_blank"
          >
            <motion.img
              src={linkedinIcon}
              variants={blockAppear}
              initial="hidden"
              animate="visible"
              whileHover={{ scale: 1.2 }}
              onHoverStart={(e) => {}}
              onHoverEnd={(e) => {}}
              custom={2.5}
              className="text-4xl font-bold text-slate-50"
            />
          </a> */}
          {/* <motion.div
            variants={blockAppear}
            initial="hidden"
            animate="visible"
            whileHover={{ scale: 1.2 }}
            onHoverStart={(e) => {}}
            onHoverEnd={(e) => {}}
            custom={2.5}
          > */}
          <motion.div
            variants={blockAppear}
            initial="hidden"
            animate="visible"
            whileHover={{ scale: 1.2 }}
            onHoverStart={(e) => {}}
            onHoverEnd={(e) => {}}
            className="rounded-xl border-4 border-neutral-800 p-1 hover:cursor-pointer"
            custom={2.5}
          >
            <FaLinkedin
              size={"36.5px"}
              className="text-4xl font-bold text-slate-50 hover:cursor-pointer"
              onClick={() =>
                openLink("https://www.linkedin.com/in/omar-tahir-478488178")
              }
            />
          </motion.div>
          <motion.div
            variants={blockAppear}
            initial="hidden"
            animate="visible"
            whileHover={{ scale: 1.2 }}
            onHoverStart={(e) => {}}
            onHoverEnd={(e) => {}}
            className="rounded-xl border-4 border-neutral-800 p-1 hover:cursor-pointer"
            custom={2.5}
          >
            <a
              className="flex h-9 w-9 max-h-9 max-w-9 items-center justify-center"
              href="https://personal-page-assets.s3.amazonaws.com/Resume+-+2024.pdf"
              target="_blank"
            >
              <img
                src={resumeIcon}
                className="text-4xl font-bold text-slate-50 max-h-8 max-w-8"
                alt="Resume Icon"
              />
            </a>
          </motion.div>
        </Grid>
      </Grid>
    </section>
  );
}

export default HomeSection;
