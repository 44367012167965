import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const ProjectCard = ({ project }) => {
  const { id, title, description, img, url, tags, pathName } = project;

  return (
    <div className="flex flex-col justify-between px-3 py-3 h-full bg-neutral-950 rounded-2xl border-4 border-neutral-800 p-4">
      <div className="max-w-sm rounded overflow-hidden shadow-lg">
        <img className="w-full" src={img} alt="Sunset in the mountains" />
        <div className="px-6 py-4">
          <div className="font-bold text-xl mb-2 text-white">{title}</div>
          <p className="text-stone-300 text-base">{description}</p>
        </div>
        <div className="px-6 py-3 flex gap-2">
          {tags.map((tag) => (
            <span
              key={tag}
              className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700"
            >
              {`#${tag}`}
            </span>
          ))}
        </div>
      </div>
      <div className="pt-5 px-6 py-4 text-base font-semibold leading-7">
        <Link to={`/projects/${pathName}`}>
          <motion.span
            whileHover={{ scale: 1.05 }}
            onHoverStart={(e) => {}}
            onHoverEnd={(e) => {}}
            className="flex justify-center w-full bg-indigo-400 rounded-md px-4 py-2 text-md font-semibold text-gray-700 hover:cursor-pointer"
          >
            <span className="text-white transition-all duration-200 group-hover:text-white">
              View &rarr;
            </span>
          </motion.span>
        </Link>
      </div>
    </div>
  );
};

export default ProjectCard;
